@media screen and (max-width:1340px) {
    .navbar-image img{
        width: 355px;
        height: 127px;
    
    }
}
@media screen and (max-width:1199px){
    .navbar-image img {
        width: 270px;
        height: 127px;
    }
    .footer-top,.planeDrifting,.btn-blue,.text-absolute{
        display: none;
    }
    .footer{
        padding: 80px 0px 0px 0px
    }
}
@media screen and (max-width:1024px){
    .carousel-caption h3{
        font-size: 40px;
        line-height: 50px;
    }
    .services h1{
        font-size: 35px;

    }
    .about-us h2, .request-quote h2, .testimonial h2{
        font-size: 28px;
        line-height: 39.57px;
    }
    .about-us h6, .request-quote h6, .testimonial h6, .services h6{
        font-size: 18px;
    }
    .services h4{
        font-size: 22px;
        /* line-height: 18px; */
    }

    .navbar-image{
        display: none;
    }
}
@media screen and (max-width:991px){
    .about-us {
        margin: 50px 0 0px 0px;
    }
    .client-1, .client-2, .client-3{
        position: unset;
    }
    .margin-minus{
        margin: 0;
    }
    .absolute{
        bottom: -31%;
    }
    .project-images img{
       width: 100px;
    }
    .testimonial-img{
        display: none;
    }
}
@media screen and (max-width:767px){
    #about-us:before, #Freight:before, #services:before{
        height: 0px ;
    }
    .request-quote p{
        font-size: 14px;
        line-height: 30px;
    }
    .project-images img{
        padding: 20px 0;
        width: 80px;
     }
    /* .project-images {
        grid-template-columns: repeat(2, 1fr);
    } */
}
@media screen and (max-width:575px){
    .about-us h2, .request-quote h2, .testimonial h2{
        font-size: 24px ;
    }
    .testimonial{
        padding: 110px 0 45px 0px;
    }
    .carousel-caption h3{
        font-size: 32px;
        line-height:42px
    }
    .my-slider{
        padding: 0 20px;
    }
    .services h1{
        font-size: 24px;
        line-height: 32px;
    }
    .services .box h4{
        font-size: 16px;
    }
    .services .box p{
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 8px !important;
    }
    .project-images div{
        text-align: center;
    }
    .project-images {
        grid-template-columns: repeat(3, 1fr);
    }
}