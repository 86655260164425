.top-navbar .nav-link {
  font-size: 13px;
  font-weight: 500;
  line-height: 14.52px;
  font-family: "Inter", sans-serif;
}

.common-navbar .nav-link {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 19.36px;
  color: #000000;
}

.carousel-wrapper {
  height: 100vh;
  /* Full height of the viewport */
  position: relative;
}

.carousel-wrapper .carousel-indicators .active {
  background-color: #005059;
  height: 12px;
  width: 34px;
  /* border-radius: 50px; */
  border: 1px solid #fff;
}

.carousel-wrapper .carousel-indicators button {
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid #fff;
  opacity: 1;

}

.logistics-img {
  position: absolute;
  right: -42px;
  bottom: -15px;
}

.logistics-img img {
  width: 50px;
}

.carousel-wrapper .carousel-item {
  height: 100vh;
  /* Full height of the viewport */
}

.carousel-wrapper .carousel-item img {
  height: 100vh;
  /* Full height of the viewport */
  object-fit: cover;
  /* Cover the entire container */
}

.carousel-caption {
  position: absolute;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.carousel-indicators {
  bottom: 40% !important
}

.carousel-caption h3 {
  font-size: 60px;
  font-weight: 900;
  line-height: 77.45px;
  font-family: "Inter", sans-serif;
  margin-bottom: 20px;

}

.carousel-caption .btn {
  background: linear-gradient(180deg, #FD5523 0%, #F97047 50%, #EB8263 100%);
  /* Change color as needed */
  box-shadow: 0px 4px 3.9px 0px #00000059;
  font-size: 14px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  line-height: 16.94px;
  border-radius: 30px;
  min-width: 174px;
  min-height: 45px;
}


.service-card {
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  background: #EDEDED;
  border: 1px solid #d9d9d9;
}

.Freight {
  margin-top: -12%;
}

.service-card .card-title {
  color: #005059;
  font-weight: 700;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 24.2px;

}

.service-card .card-text {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  text-align: justify;
  line-height: 35px;
}

.service-card .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.service-card:hover {
  border: none;
}

.service-card:hover .card-img-overlay {
  opacity: 1;
}

.service-card .card-img-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.icon {
  font-size: 2rem;
  color: #FD5523;
  margin-bottom: 1rem;
}

.card-body {
  z-index: 1;
  position: relative;
}

.card.service-card:hover .card-title,
.card.service-card:hover .card-text,
.card.service-card:hover .card-body a {
  color: #fff;
}

.card.service-card:hover .card-body a svg path {
  stroke: #fff;
}

.card-body a {
  color: #FD5523;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

/*about-us*/
.about-us {
  /* margin: 100px 0 0px 0px; */
}

.about-us h6,
.request-quote h6,
.testimonial h6,
.services h6 {
  font-weight: 700;
  font-size: 22px;
  line-height: 26.63px;
  font-family: "Inter", sans-serif;
  color: #005059;
  text-transform: uppercase;
}

.about-us .icon {
  font-size: 2rem;
  color: #FD5523;
}

.text-orange {
  color: #FD5523;
}

.about-us h2 span.text-orange {
  color: #FD5523;
}

.about-us p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: justify;
  line-height: 35px;
}

.about-us .card {
  background-color: #F5F5F5;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 2px solid #FD5523;
}

.about-us h2,
.request-quote h2,
.testimonial h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 43.57px;
  font-family: "Inter", sans-serif;
  color: #005059;

}

.request-quote p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  ;
  line-height: 35px;
  text-align: justify;
  color: #FFFFFF;
}

.about-us .main-image {
  /* width: 100%; */
}

.about-us .small-image {
  width: 50%;
  bottom: 10%;
  right: 15%;
  border: 3px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us .card-body h6 {
  text-align: left;
  color: #005059;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 21.78px;

}

.about-us .card-body p {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.about-us .card-body .icon {
  font-size: 1.5rem;
}

/*request-quote*/
.request-quote {
  background-image: url('../public/landingPageImg/bg-serviuce.png');
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;
}

.form-box {
  background-color: #fff;
  border-radius: 10px 10px 3px 3px;
  padding: 20px;

}

.form-box .form-control,
.form-box .form-select,
.footer-links input {
  background-color: #EAEAEA;
  min-height: 39.46px;

  border: none;
  border-radius: 0px;

}

.btn-orange {
  background: #FD5523;
  min-height: 34px;
  min-width: 120px;
  border: none;
  font-weight: 700;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  line-height: 35px;
  color: #fff;
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.form-box svg {
  position: absolute;
  top: 7px;
  right: 8px;
}

.progress-bar {
  width: 300px;
  height: 3px;
  background-color: #eee;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.progress {
  background-color: #FD5523;
  height: 100%;
  width: 0%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px 0 0 5px;
  transition: width 0.5s ease;
}

.margin-minus {
  margin-top: -7%;
}

.absolute {
  position: absolute;
  bottom: -20%;
  right: 0;

}

.testimonial-card {
  background: #EFEEEE;
  border: none;
  border-left: 2px solid #FD5523;
  border-radius: 0px;
}

.testimonial-card h6 {
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  font-family: "Inter", sans-serif;
}

.testimonial-card p {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Inter", sans-serif;
  text-align: left;
  color: #005059;
}

.testimonial-vector {
  position: absolute;
  top: -23px;
  right: 16px;

}

.testimonial {
  padding: 110px 0 80px 0px;
  background: #F5F5F5;

}

.client-review h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
}

.client-review p {
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  font-family: "Inter", sans-serif;
}

.testimonial-img {
  margin-top: 107px;
  text-align: end;
}

.client-1,
.client-2,
.client-3 {
  position: absolute;
  top: 0;
}

.client-1 {
  left: 0;
}

.client-2 {
  left: 44px;
}

.client-3 {
  left: 84px;
}

.project-images {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footer {
  background-image: url('../public/landingPageImg/bg-serviuce.png');
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;
  padding: 200px 0px 0px 0px;
}

.footer-top {
  position: absolute;
  top: 0;
  left: 0;
}

.footer-info p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  color: #FFFFFF;
}

.footer-links h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 33.89px;
  font-family: "Inter", sans-serif;
  color: #FFFFFF;
  text-transform: capitalize;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links ul li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Inter", sans-serif;
  color: #FFFFFF;
  text-decoration: none;
  margin-left: 6px;

}

.footer-links p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 25px;
  color: #FFFFFF;
}

.contact-us li a {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
  font-family: "Inter", sans-serif;
  color: #FFFFFF;
  text-decoration: none;
  margin-left: 6px;
}

.contact-us li {
  display: grid;
  grid-template-columns: 1fr 8fr;
}

.contact-us svg {
  margin-top: 7px;
}

.boredr-top-custom {
  border-top: 2px solid #FFFFFF;

}

.boredr-top-custom h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  font-family: "Inter", sans-serif;
  color: #FFFFFF;
  padding: 30px 0;
}

.btn-blue {
  background: #005059;
  min-height: 34px;
  min-width: 120px;
  border: none;
  font-weight: 700;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  line-height: 35px;
  color: #fff;
  position: absolute;
  top: 37px;
  right: 23%;
  text-transform: uppercase;

}

.text-absolute {
  text-transform: capitalize;
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  line-height: 33.89px;
  position: absolute;
  top: 39px;
  left: 40%;
  transform: translate(-50%, 0);



}

.footer-img {
  position: absolute;
  top: 0;
  right: 30px;
}

.planeDrifting {
  position: absolute;
  top: 34px;
  left: 13%;
}

.services h4 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  line-height: 29.05px;
  color: #005059;
  text-transform: uppercase;
}

.services h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58.09px;
  color: #FD5523;
  font-family: "Inter", sans-serif;
}

.services {
  background-image: url('../public/landingPageImg/service-bg.png');
  padding: 1px 0 50px 0;
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;

}

.slick-dots li button:before {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  padding: 30px 0;
  /* overflow-x: hidden; */
}

.slick-list {
  height: 100%;
}

.slick-dots {
  width: 100%;
  left: 0;
}

.my-slider {
  padding: 0 70px;
  height: 100%;
}

.slick-initialized .slick-slide {


  margin: 0 45px 0 0;

}

.box a {
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  color: #005059;
  text-decoration: none;
}

.box p {
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  text-align: start;
  font-family: "Inter", sans-serif;
}


.border-blue {
  border-right: 2px solid #005059;
}

.box {
  position: absolute;
  bottom: -12%;
  right: 0;
  width: 92%;
}

.slick-next,
.slick-prev {
  z-index: 5;
}

.slick-next {
  right: 15px;
}

.slick-prev {
  left: 15px;
}

.slick-next:before,
.slick-prev:before {
  color: #000;
  font-size: 26px;
}

.slick-dots {
  text-align: center;
  padding: 10px 0;
  bottom: -70px;
}

.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: #0000;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 2px solid #ccc;
  /* Dots border color */
  border-radius: 50%;
  background-color: #ccc;
  /* Dots background color */
  transition: background-color 0.3s, border-color 0.3s;
}

.slick-dots .slick-active button {
  border-color: #0000;
  background-color: #0000;
}

.slick-dots li button:before {
  font-size: 13px !important;
}

.slick-dots li.slick-active button:before {
  color: #ff5722 !important;
  /* height: 20px; */
  opacity: 1;
  /* width: 20px; */
  line-height: 23px;
  font-size: 13px;
}

/* .slick-dots li.slick-active button {
    background-color: #ff5722;
    position: relative;
    border: 1px solid #ff5722;
  } */
.slick-dots li.slick-active button::after {
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #ff5722;
  border-radius: 23px;
}

.circle {
  position: absolute;
  top: -12px;
  background: #fff;
  z-index: 1;
}

.plane-img {
  position: absolute;
  top: -15px;
  background: #fff;
  right: 35%;
  z-index: 1;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid #fd55232b;
}

.Waves-img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/*get-a-quote*/
.main-nav-det h2 {
  font-weight: 700;
  font-size: 25px;
  line-height: 1.42;
  color: #000;
}

.main-nav-det h4 {
  display: flex;
  align-items: center;
  padding: 20px 0;
  gap: 10px;
  font-size: 18px;
  text-transform: capitalize;
}

.input_box_section {
  margin-bottom: 15px;
}

.input_box_section label {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.input_box_section select {
  border: 1px solid #cccccc;
  padding: 10px;
  width: 100%;
  background-color: #F3F4F6;
  margin-top: 10px;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
}

.alfa_listing ul {
  display: flex;
  text-align: center;
}

ol,
ul {
  list-style-type: none;
}

.alfa_listing ul li .input_box_section input {
  text-align: center;
}

.alfa_listing ul li .input_box_section input {
  margin-top: 0;
}

.input_box_section input {
  border: 1px solid #cccccc;
  margin-top: 7px;
  outline: none;
  padding: 5px 10px;
  width: 100%;
}

.main_package_formar input {
  padding: 10px;
  width: 235px;
  text-align: center;
  /* background-color: #F3F4F6; */
  margin-top: 10px;
  outline: none;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  color: #000;
  margin: 24px 0;
  cursor: pointer;
}

.main-nav-det-button a {
  padding: 11px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  background: #FD5523;
  color: #000;
  background: #ccc;
}

.related_to_main {
  padding: 20px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.folder_main_bro span {
  padding-right: 10px;
}

.folder_main_bro {
  height: 50px;
  width: 200px;
  border-radius: 5px;
  background: #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #FD5523;
  color: #FD5523;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_select_photo {
  padding: 15px 0;
}

.main_select_photo ul {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 14px;
  align-items: start;
}

.file-input>[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.main_select_photo ul li {
  padding: 0 10px;
}

.main_list_numvar {
  list-style: auto;
  padding-left: 16px;
}

.alfa_listing ul li:nth-child(even) .input_box_section input {
  border: none !important;
  background-color: #fff !important;
}

.input_box_section input {
  border: 1px solid #cccccc;
  padding: 10px 10px;
  width: 100%;
  /* background-color: #F3F4F6; */
  margin-top: 10px;
  outline: none;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.alfa_listing-kg .input_box_section input,
.input_box_section select {
  margin-top: 0px;
}

.alfa_listing-kg ul {
  display: flex;
  text-align: center;
}

.main-nav-det-button {

  margin-top: 40px;
}

.alfa_listing-kg ul li {
  padding: 0 10px;
}

.alfa_listing-kg ul,
.main_select_photo ul {
  padding: 0;
}

.main-nav-det-button a:last-child {
  background: #FD5523;
  color: #fff;
}

.main-nav-det-button a {
  padding: 11px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  background: #FD5523;
  color: #000;
  background: #ccc;
}

.alfa_listing-kg ul li {
  padding: 0 10px 0 0;
}

.por_main_detail {
  position: relative;
}

.por_main_detail span img {
  position: absolute;
  z-index: 99;
  top: 46px;
  right: 15px;
}

.main-nav-det-button a:hover {
  background: #FD5523;
  color: #fff;
}

.project-box {
  background: #fff;
  max-width: 400px;
  border-radius: 6px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 4px 0px #c5c5c5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;

}

.project-box img {
  width: 100px;
  object-fit: contain;

}

#about-us:before,
#Freight:before,
#services:before {
  display: block;
  content: "";
  height: 130px;
  visibility: hidden;
}

.error {
  color: #ed0800;
  font-size: 12px;
  margin-top: 6px;
}

.bg-none {
  background: none;
  border: none;
}

.custom-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.search-btn {
  background-color: #ff4400 !important;
  color: #fff !important;
  text-transform: capitalize;
}

.image-input-box,
.video-input-box {
  position: relative;
}

/* .quote-request-form{
  background-image: url('../public/landingPageImg/service-bg.png');
    padding: 1px 0 50px 0;
    height: 100%;
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-size: contain;
} */
.folder_main_bro img {
  mix-blend-mode: difference;
}

.react-datepicker__current-month {
  font-size: 18px !important;
  font-weight: 700;
  line-height: 30px !important;
  font-family: "Inter", sans-serif;
  color: #005059;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.custom-date-picker .react-datepicker-wrapper {
  width: 100% !important;
}

.ovrelay-form-submission {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.form-group {
  display: flex;
  align-items: start;
  justify-content: start;
}

.input-section {
  margin-right: 20px;
}

.quantity-input {
  display: flex;
  align-items: center;
}

.quantity-input input {
  width: 100%;
  /* padding: 5px; */
  /* border-radius: 4px; */
  /* border: 1px solid #ddd; */
  /* text-align: center; */

}

.quantity-input .check-icon {
  margin-left: 8px;
  color: green;
}

.packaging-options {
  display: flex;
}

.package-btn {
  background-color: #4e606224;
  border: 1px solid #00515a;
  border-radius: 5px;
  padding: 8px 20px;
  margin: 0 5px;
  color: #00515a;
  text-transform: capitalize;
}

.package-btn.active {
  background-color: #799ea361;
  color: #023f46;
  border: 2px solid #00515a;
}

.package-btn .check-icon {
  margin-left: 5px;
  color: #00515a;
}

.weight-unit-form {
  display: flex;
  flex-direction: column;
}



.required {
  color: red;
}

.weight-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  max-width: 200px;
}

.weight-input {
  border: none;
  width: 60px;
  text-align: right;
  margin-right: 10px;
  font-size: 16px;
}

.weight-input:focus {
  outline: none;
}

.validation-check {
  color: green;
  font-size: 18px;
  margin-right: 10px;
}

.unit-select {
  border: none;
  background: transparent;
  font-size: 16px;
}

.unit-select:focus {
  outline: none;
}

/*fdgfdgdfg*/
.form-group-1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}



label {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.required {
  color: red;
}

.dimensions-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  /* padding: 5px; */
  width: fit-content;
  padding-right: 10px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 1px;

}

.border-right-0 {
  border-right: none !important;
}

.remoove {
  border: 1px solid #ff0808;
  color: #ff0000;
}

.dimension-input {
  border: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  border-right: 1px solid #ccc;
}

.dimension-input:focus {
  outline: none;
}

.validation-error {
  position: absolute;
  right: -10px;
  color: red;
  font-size: 18px;
}

.unit-select {
  border: none;
  background: transparent;
  font-size: 16px;
  padding: 5px;
}

.unit-select:focus {
  outline: none;
}

.volume-link {
  margin-top: 5px;
  color: #007bff;
  text-decoration: none;
}

.volume-link:hover {
  text-decoration: underline;
}

.dimension-input-1 {
  border: none;
  width: 100px;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  border-right: 1px solid #ccc;
}

.dimension-input-1:focus {
  outline: none;
}

.add-package {
  border: 1px solid #00515a;
  border-radius: 6px;
  padding: 8px 10px;
}

.border-top {
  border-top: 1px solid #b9b9b9fa !important;
  margin: 38px 0;
  padding: 40px 0;
}
.quote-request-form{
  background-image: url('../public/landingPageImg/slider-img1.png');
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.radius-6{
  border-radius: 6px;
}